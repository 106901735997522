@font-face {
    font-family: "Open Sans";
    src: url("OpenSans-ExtraBold.eot");
    src: url("OpenSans-ExtraBold.eot?#iefix") format("embedded-opentype"), url("OpenSans-ExtraBold.woff2") format("woff2"),
    url("OpenSans-ExtraBold.woff") format("woff"), url("OpenSans-ExtraBold.ttf") format("truetype"),
    url("OpenSans-ExtraBold.svg#OpenSans-ExtraBold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("OpenSans-Light.eot");
    src: url("OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("OpenSans-Light.woff2") format("woff2"),
    url("OpenSans-Light.woff") format("woff"), url("OpenSans-Light.ttf") format("truetype"),
    url("OpenSans-Light.svg#OpenSans-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("OpenSans-Bold.eot");
    src: url("OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("OpenSans-Bold.woff2") format("woff2"),
    url("OpenSans-Bold.woff") format("woff"), url("OpenSans-Bold.ttf") format("truetype"),
    url("OpenSans-Bold.svg#OpenSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("OpenSans-Medium.eot");
    src: url("OpenSans-Medium.eot?#iefix") format("embedded-opentype"), url("OpenSans-Medium.woff2") format("woff2"),
    url("OpenSans-Medium.woff") format("woff"), url("OpenSans-Medium.ttf") format("truetype"),
    url("OpenSans-Medium.svg#OpenSans-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("OpenSans-SemiBold.eot");
    src: url("OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("OpenSans-SemiBold.woff2") format("woff2"),
    url("OpenSans-SemiBold.woff") format("woff"), url("OpenSans-SemiBold.ttf") format("truetype"),
    url("OpenSans-SemiBold.svg#OpenSans-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("OpenSans-Regular.eot");
    src: url("OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("OpenSans-Regular.woff2") format("woff2"),
    url("OpenSans-Regular.woff") format("woff"), url("OpenSans-Regular.ttf") format("truetype"),
    url("OpenSans-Regular.svg#OpenSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
