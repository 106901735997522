.contentTextCardTwo .MuiBox-root.css-1nni0jf {
    padding-bottom: 20px;
}

.contentTextCardTwo .MuiBox-root.css-1nni0jf p.MuiTypography-root.css-6unmpt-MuiTypography-root {
    font-weight: 300;
}

.contentTextCardTwo .css-1nni0jf {
    border: none;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.viewOnlyTextareaContent {
    padding-top: 3px;
    overflow: auto !important;
    white-space: wrap;
    height: 24px;
}


.STEP_CLASSNAME_SELECT_ENTITY_TYPE.MODE_EDIT .customFormCard.entity-type .cancel-save-btn-wrapper,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .additional_contact_information.customFormCard .cancel-save-btn-wrapper {
    position: static !important;
}

@media (min-width: 600px) and (max-width: 768px) {
    .STEP_CLASSNAME_ENTITY_INFORMATION.MODE_ADD .MuiGrid-root.MuiGrid-container,
    .STEP_CLASSNAME_ENTITY_INFORMATION.MODE_EDIT .MuiGrid-root.MuiGrid-container {
        margin-bottom: inherit !important;
    }

    .STEP_CLASSNAME_ENTITY_INFORMATION.MODE_ADD .identifiers .MuiGrid-root.MuiGrid-container {
        padding-bottom: 1.5rem;
    }

    .STEP_CLASSNAME_ENTITY_INFORMATION.MODE_ADD button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
        padding: 0 !important;
    }

    .STEP_CLASSNAME_ENTITY_INFORMATION.MODE_ADD input.MuiInputBase-inputAdornedEnd {
        padding-right: 0;
    }
}
