body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px !important;
}

.fullWidth {
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.m-0 {
    margin: 0px !important;
}

/* to hide number textbox arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* .MuiModal-root .MuiList-root li,
.MuiModal-root .MuiList-root li span.MuiTypography-root {
  font-size: 14px;
  color: #333;
  font-weight: 300;
} */

.MuiModal-root .MuiList-root li .MuiCheckbox-root {
    padding: 0px 5px;
}

.MuiModal-root .MuiList-root li .MuiSvgIcon-root {
    height: 0.8em;
    width: 0.8em;
}

.datatable-main {
    box-shadow: none !important;
}

.datatable-main .MuiTableHead-root .MuiTableCell-head {
    background-color: #e6eff2;
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    font-weight: 600;
    border: 1px solid #d7d7d7;
    border-bottom: 0px;
}

.datatable-main .MuiTableBody-root .MuiTableCell-root {
    background-color: #fff;
    padding: 11px 12px;
    font-size: 14px;
    color: #333;
    border: 1px solid #d7d7d7;
    line-height: 1;
}

.datatable-main .MuiTableBody-root .MuiTableRow-root:nth-child(even) .MuiTableCell-root {
    background-color: #f2f2f2;
}

.datatable-main .MuiTable-root td,
.datatable-main .MuiTable-root {
    position: relative;
}

.link-data {
    /* position: absolute !importgant; */
    right: 40px;
    top: -50px;
    z-index: 1001;
}

.datatable-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
}

.datatable-bottom .MuiTablePagination-root {
    width: 60%;
}

.datatable-bottom .MuiTablePagination-selectLabel {
    color: #4d8da4;
    font-size: 14px;
}

.datatable-bottom .MuiTablePagination-root .MuiToolbar-root {
    min-height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.datatable-bottom .MuiTablePagination-root .MuiToolbar-root p {
    margin: 0px;
}

.datatable-bottom .export-link {
    margin-top: 0px !important;
}

.datatable-bottom .MuiButtonBase-root,
.datatable-bottom .MuiButtonBase-root:hover {
    color: #4d8da4;
}

.datatable-bottom .MuiButtonBase-root .MuiSvgIcon-root {
    height: 1.3em;
    width: 1.3em;
}

.datatable-bottom .MuiButtonBase-root .MuiSvgIcon-root {
    fill: #4d8da4;
}

.datatable-bottom .MuiButtonBase-root.Mui-disabled .MuiSvgIcon-root {
    fill: #d7d7d7;
}

.datatable-bottom .MuiTablePagination-displayedRows {
    font-size: 14px;
    color: #7f7f7f;
}

.datatable-bottom .MuiTablePagination-select {
    font-size: 14px;
    color: #7f7f7f;
    margin-top: 4px;
}

.remove-margin .MuiPaper-root {
    margin-bottom: 20px !important;
}

.datatable-bottom .MuiButtonBase-root {
    padding-top: 3px;
    padding-bottom: 3px;
}

.MuiList-root.commonlistul {
    margin-left: 40px;
    padding: 0px;
    /* Conflict Resolution Candidate
      /* margin-bottom: 60px; */
    margin-bottom: 20px;
}

.MuiList-root.commonlistul.history {
    margin-bottom: 25px;
}

.MuiList-root.commonlistul li.MuiListItem-root {
    background: #e6eff2;
    border-bottom: 1px solid #4d8da4;
    font-size: 16px;
    color: #333;
}

.MuiList-root.commonlistul li.MuiListItem-root .paneldetail {
    margin: 0px;
    padding: 0px;
}

.MuiList-root.commonlistul li.MuiListItem-root .MuiAccordion-root {
    background-color: transparent;
    min-height: auto;
    width: 100%;
    box-shadow: none;
}

.MuiList-root.commonlistul li.MuiListItem-root .MuiAccordionSummary-content {
    margin: 0px;
}

.MuiList-root.commonlistul li.MuiListItem-root .MuiAccordionSummary-root {
    min-height: auto;
    padding-left: 0px;
}

.MuiList-root.commonlistul li.MuiListItem-root .MuiAccordionSummary-content {
    order: 2;
}

.MuiList-root.commonlistul li.MuiListItem-root .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
    fill: #4d8da4;
    width: 1.5em;
    height: 1.5em;
}

.tabs-main > .MuiBox-root {
    border-bottom: 1px solid #283459;
}

.tabs-main .MuiButtonBase-root.Mui-selected {
    background-color: #e6eff2;
    border: 1px solid #4d8da4;
    /* border-radius: 10px 10px 0px 0px; */
    border-bottom: none;
    min-height: auto;
    color: #283459;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
}

.tabs-main .MuiButtonBase-root {
    border-bottom: none;
    /* padding: 7px 15px; */
    min-height: auto;
    /* color: #283459; */
    /* font-weight: 300; */
    font-size: 15px;
    text-transform: capitalize;
}

.tabs-main .MuiTabs-root {
    min-height: auto;
}

.tabs-main .MuiTabs-indicator {
    display: none;
}

.filter-main {
    display: flex;
    margin-bottom: 30px;
    padding-left: 20px;
    align-items: flex-end;
}

.filter-main .select-common {
    width: 162px;
    margin: 0px 20px;
}

.filter-main .select-common .MuiNativeSelect-select,
.filter-main .select-common .MuiNativeSelect-select option {
    font-size: 14px;
    font-weight: 300;
    color: #000;

}


/* .filter-main .select-common .MuiNativeSelect-select option {
  padding: 0px 5px !important;
} */

.filter-main .select-common .MuiInput-root::before {
    border-bottom: 2px solid #4d8da4;
}

.filter-main .searchbox {
    width: 35%;
}

.filter-main .icon-list {
    float: right;
}

.filter-main .icon-list {
    width: 35%;
    text-align: right;
}

.icon-list .MuiSvgIcon-root {
    height: 1.3em;
    width: 1.3em;
    fill: #4d8da4;
    margin: 0px 8px;
}

.filter-main .searchbox .MuiAutocomplete-input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #4d8da4;
}

.filter-main .MuiAutocomplete-root {
    width: 100%;
}

.filter-main .MuiAutocomplete-root.Mui-focused {
    border: none;
}

.filter-main .MuiAutocomplete-root.Mui-focused .MuiAutocomplete-input {
    border: none !important;
    box-shadow: none;
}

.searchbox {
    position: relative;

}

.searchbox .MuiSvgIcon-root {
    position: absolute;
    bottom: 0px;
    height: 1em;
    width: 1em;
    fill: #4d8da4;
}

.filter-with-data .MuiButton-text {
    border: 1px solid #4d8da4;
    background-color: #f2f2f2;
    height: 40px;
    font-size: 14px;
    font-weight: 300;
    margin-right: 15px;
    text-transform: capitalize;
}

.filter-with-data .MuiButton-text span {
    color: #333;
}

.filter-with-data {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.MuiButton-root.MuiButton-contained.plus-filter-btn {
    background: #e6eff2;
    width: 34px;
    padding: 5px;
    min-width: auto;
    height: 40px;
    color: #4d8da4;

}

.MuiButton-root.MuiButton-contained.plus-filter-btn .MuiSvgIcon-root {
    font-size: 1.2rem;
    height: 34px;
    width: 40px;
}

.popup-data {
    width: 214px;
    background: #e6eff2;
    padding: 20px 20px;
    border: 2px solid #4d8da4;
    border-radius: 8px;
    /* position: relative; */
}

.popup-data ul {
    padding: 0px;
}

.popup-data ul li {
    padding: 2px 0px;
}

.popup-data ul li .MuiButton-root {
    font-size: 13px;
    color: #333;
    text-decoration: none;
    outline: none;
    min-width: 100%;
    padding: 0px;
    justify-content: flex-start;
    line-height: 1.2;
    text-transform: none;
}

.popup-data ul li .MuiButton-text .MuiTouchRipple-root {
    display: none;
}

.popup-data ul li .MuiButton-root:hover {
    background-color: transparent;
}

/* .popover-main .MuiPopover-paper,
#simple-popover .MuiPopover-paper {
  background-color: transparent !important;
  overflow: initial !important;
  box-shadow: none !important;
} */

.MuiPopover-paper .apply-popup {
    left: 165%;
    width: 160px;
    padding: 10px 0px 5px 5px;
}

.MuiPopover-paper .apply-popup .MuiGrid-item {
    max-width: 100%;
    flex-basis: 100%
}

.MuiPopover-paper .apply-popup .MuiRadio-root {
    padding: 0px 8px;
}

.MuiPopover-paper .apply-popup .MuiRadio-root {
    margin-right: 7px;
}

.MuiPopover-paper .apply-popup .MuiFormControlLabel-root {
    margin-right: 0px;
}

.MuiPopover-paper .apply-popup .MuiTypography-root {
    font-size: 14px;
    color: #333;
    font-weight: 400 !important;
}

.MuiPopover-paper .apply-popup .cancel-btn {
    background-color: #fff;
    border: 1px solid #d7d7d7;
    width: 80px;
    font-size: 12px;
    font-weight: 400;
    min-width: 70px;
    justify-content: center;
    margin-right: 7px;
    margin-top: 15px;
    height: 20px;
}

.MuiPopover-paper .apply-popup .apply-btn,
.MuiPopover-paper .apply-popup .apply-btn:hover {
    background-color: #4d8da4;
    border: 1px solid #4d8da4;
    width: 80px;
    font-size: 12px;
    font-weight: 400;
    min-width: 65px;
    color: #fff;
    justify-content: center;
    margin-right: 5px;
    margin-top: 15px;
    height: 20px;
}

.filter-btn {

    color: #a9a9a9 !important;
    align-items: center !important;
    cursor: pointer;
}

.filter-btn .MuiTypography-root {
    color: #333;
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.filter-btn .MuiTypography-root .MuiSvgIcon-root {
    height: 1em;
    width: 1em;
    fill: #4d8da4;
    font-size: 1.3em;
}

.hide-icon {
    pointer-events: none;
    scale: 1.4;
    margin-top: 4px;
    font-size: 2rem;
}

.dot-icon {
    color: #4d8da4;
    font-size: 2rem;
    /* Conflict Resolution Candidate
      height: 1.5em !important;
      width: 1.5em !important;
      cursor: pointer;
     */
    /* height: 1.5em !important;
      width: 1.5em !important; */
    cursor: pointer;
    scale: 1.4;
    margin-top: 4px;
}

.dot-icon-2 {
    color: #4d8da4;
    font-size: 2rem;
    /* height: 1.5em !important;
    width: 1.5em !important; */
    cursor: pointer;
    scale: 1.4;
    margin-top: 8px;
}

.dot-icon-disabled {
    color: grey;
    font-size: 2rem;
    cursor: pointer;
    scale: 1.4;
    margin-top: 4px;
}

.dot-icon-3 {
    color: #4d8da4;
    cursor: pointer;
}

.export-link {
    color: #4d8da4;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}

.link-data {
    width: 260px;
    background: #fff;
    border-radius: 0px;
    padding: 6px;
}

.link-data .MuiList-root a {
    color: #4d8da4;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 6px;
    border-radius: 4px;
}

.link-data .MuiList-root a:hover {
    background-color: #4d8da4;
    color: #fff;
    font-weight: 500;
}

.apply-popup .MuiInputBase-root {
    width: 85%;
    border: 1px solid #4d8da4 !important;
    background-color: #fff;
    /* border: none; */
    height: 25px;
    border-radius: 4px;
}

.apply-popup .MuiOutlinedInput-notchedOutline {
    display: none;
}

.filter-data {
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -o-transition: all 2s ease;
    -ms-transition: all 2s ease;
    transition: all 2s ease;
}

.pending-accordion {
    padding: 0px !important;
}

.pending-accordion .MuiAccordionSummary-root {
    background: #e6eff2;
}

.pending-accordion .MuiAccordionDetails-root {
    background-color: #ffff;
    padding: 20px;
}

.pending-accordion .MuiAccordionDetails-root .MuiButtonBase-root {
    font-size: 14px;
}

.MuiDataGrid-toolbarContainer .MuiButtonBase-root {
    text-transform: capitalize;
}

.MuiTypography-h4.disable-link {
    background-color: #aaaaaa;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}

body ul.disable-link .MuiListItem-root {
    background-color: rgba(199, 200, 202, 0.38) !important;
    color: #333;

}


/* Popper default css */
.arrowPopper {
    left: -10px;
}

.PhoneInputInput {
    min-height: 30px;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
}

/* .MuiPopover-root .MuiPaper-root.MuiPopover-paper {
  max-height: 300px !important
} */

/* for hide browser default icons from number input */

.hideBrowserDefault input::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}

.hideBrowserDefault input::-webkit-inner-spin-button {
    appearance: none;
    -Webkit-appearance: none;
    margin: 0;
}

.hideBrowserDefault input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
}

form:focus-visible {
    outline: none;
}

/* body ul.disable-link .MuiListItem-root a{
cursor: not-allowed;
} */

html {
    scroll-behavior: smooth;
}

.regular-label-weight {
    font-weight: 400;
}

.quick-link-style {
    width: 251px;
    /* padding: 1.2px 2px 1.2px 2px !important; */
    padding: 6px !important;
    border-radius: 4px;
    border: 1px solid #4d8da4;
    background-color: #ffffff;
    box-sizing: border-box;
}

.quick-link-style .MuiList-root p {
    /* cursor: pointer; */
    color: #4d8da4;
    width: 100%;
    font-size: 14px;
    text-decoration: none;
    /* text-transform: uppercase; */
    text-align: center;
    /* padding: 5px 2px 5px 2px; */
    min-height: 46.67px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 4px;
    background-color: rgba(255, 255, 255, 1);
}

.quick-link-style .MuiList-root p:hover {
    background-color: #4d8da4;
    color: #fff;
    font-weight: 500;
}

.quick-link-style .MuiList-root p:last-of-type {
    margin-bottom: 0;
}

.quick-link-style ul {
    padding: 0px;
}

.MuiButton-sizeSmall {
    min-width: 80px !important;
    height: 30px;
}

.intro-text {
    margin: 0;
    font-weight: 300;
    line-height: normal;
}

.read-more {
    background: transparent;
    border: 0;
    padding: 0;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4d8da4;
    cursor: pointer;
}

.read-more:hover {
    font-weight: 600;
}

.btn-gray {
    background-color: #aaaaaa !important;
    border-color: #aaaaaa !important;
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
}

.btn-primary {
    background-color: #283459 !important;
    border: solid 1px #283459 !important;
    color: #fff !important;
    font-size: 14px !important;
    width: 150px !important;
    height: 40px;
    padding: 5px 5px !important;
    box-shadow: none !important;

}

.btn-primary:disabled,
.btn-primary[disabled],
.btn-primary.Mui-disabled {
    background-color: #aaaaaa !important;
    border-color: #aaaaaa !important;
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
}

.btn-primary:hover {
    background-color: #4d8da4 !important;
    border: solid 1px #4d8da4 !important;
    color: #fff !important;
    box-shadow: none !important;
}

.btn-wrapper .btn-primary {
    background-color: #283459;
    border: solid 1px #283459;
    color: #fff;
    font-size: 14px;
    width: 150px;
    height: 40px;
    padding: 5px 12px;
    box-shadow: none;
}

.btn-wrapper .btn-primary:hover {
    background-color: #4d8da4;
    border: solid 1px #4d8da4;
    color: #fff;
    box-shadow: none;
}

.cancelEntityBtn {
    background-color: #283459 !important;
    border: solid 1px #283459 !important;
    color: #fff !important;
    font-size: 14px !important;
    width: 160px !important;
    padding: 5px 5px !important;
    box-shadow: none !important;

}

.cancelEntityBtn:disabled,
.cancelEntityBtn[disabled],
.cancelEntityBtn.Mui-disabled {
    background-color: #aaaaaa !important;
    border-color: #aaaaaa !important;
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
}

.btn-wrapper .btn-secondary {
    background-color: #fff;
    border: solid 1px #c7c8ca;
    color: #4d8da4;
    font-size: 14px;
    width: 150px;
    height: 40px;
    padding: 5px 12px;
}

.btn-wrapper .btn-secondary:hover {
    background-color: #fff;
    border: solid 1px #c7c8ca;
    color: #4d8da4;
}

.btn-secondary {
    background-color: #fff !important;
    border: solid 1px #c7c8ca !important;
    color: #4d8da4 !important;
    font-size: 14px;
    width: 150px;
    height: 40px;
    padding: 5px 12px;
}

.btn-secondary:hover {
    background-color: #fff !important;
    border: solid 1px #c7c8ca !important;
    color: #4d8da4 !important;
}

.MuiDialogActions-root .btn-primary {
    background-color: #283459;
    border: solid 1px #283459;
    color: #fff;
    font-size: 14px;
    width: 150px;
    padding: 5px 12px;
    box-shadow: none;
    height: 40px;
}

.MuiDialogActions-root .btn-primary:hover {
    background-color: #4d8da4;
    border: solid 1px #4d8da4;
    color: #fff;
    box-shadow: none;
}

.label-wrapper {
    display: flex;
    align-items: flex-end;
    height: 40px;
}

.MuiFormGroup-root[role="radiogroup"] {
    margin-top: -3px;
    margin-bottom: 4px;
}

.MuiFormGroup-root[role="radiogroup"] .label-wrapper {
    display: none;
}

.radio-group .label-wrapper {
    display: none;
}

.MuiDialogContent-root .label-wrapper {
    height: auto;
}

.MuiFormHelperText-root {
    margin-top: 0 !important;
    color: #ca2c1c !important;
}

.pb-40 .customFormCard {
    padding-bottom: 40px;
}

/* Added to accomodate terms n conditions links */
.pb-80 .customFormCardTC {
    padding-bottom: 80px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.STEP_CLASSNAME_SELECT_JURISDICTION.MODE_EDIT .MuiTypography-root svg {
    scale: 1.75;
    width: 32px;
    margin-left: 5px;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.MODE_EDIT.entity-viewonly .customFormCard,
.STEP_CLASSNAME_ENTITY_INFORMATION.MODE_EDIT.identifiers-viewonly .customFormCard.identifiers,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT.identifiers-viewonly .customFormCard,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT.physical-viewonly .customFormCard.physical-address,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT.mailing-viewonly .customFormCard.mailing-address,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT.additional-viewonly .customFormCard {
    padding-bottom: 40px !important;
}

.MODE_EDIT.auction-editonly .customFormCard {
    padding-bottom: 40px !important;
}

.customFormCardRevisionRequest {
    padding-bottom: 40px !important;
}

.MODE_EDIT.altContact-editonly .customFormCard {
    padding-bottom: 40px !important;
}

.MODE_EDIT.altContact-editonly .customFormCard .cancel-save-btn-wrapper {
    margin-top: 0px !important;
}

.representative-viewonly .customFormCard.add-representatives {
    padding-bottom: 40px !important;
}

.representative-editonly .customFormCard.add-representatives {
    padding-bottom: 20px;
}


.facility-viewonly .customFormCard.add-facilities {
    padding-bottom: 40px !important;
}

/* .facility-editonly .customFormCard.add-facilities {
  padding-bottom: 20px !important;
} */

.facility-editonly .customFormCard .cancel-save-btn-wrapper {
    margin-top: -20px !important;
}

.mailing-viewonly .customFormCard {
    padding-bottom: 40px !important;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.entity-viewonly .customFormCard .MuiGrid-container:nth-of-type(2) {
    margin-top: 42px;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.MODE_EDIT.entity-viewonly .customFormCard .purpose-user-row {
    margin-top: 42px;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.entity-viewonly .customFormCard .other-classification {
    margin-top: 0;
}

/* .STEP_CLASSNAME_ENTITY_INFORMATION.entity-viewonly .customFormCard .MuiGrid-container:nth-of-type(2) {
  margin-top: 42px;
} */

.STEP_CLASSNAME_ENTITY_INFORMATION.identifiers-viewonly .customFormCard .MuiGrid-container:nth-of-type(2) {
    margin-top: 42px;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.physical-viewonly .customFormCard .MuiGrid-container:nth-of-type(2),
.STEP_CLASSNAME_CONTACT_INFORMATION.mailing-viewonly .customFormCard .MuiGrid-container:nth-of-type(2),
.STEP_CLASSNAME_CONTACT_INFORMATION.additional-viewonly .customFormCard .MuiGrid-container:nth-of-type(2) {
    margin-top: 42px;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.identifiers-viewonly .customFormCard .MuiGrid-container:nth-of-type(2) {
    margin-top: 42px;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.VIEW_ONLY .identifiers .formContent .MuiGrid-container:first-of-type .MuiGrid-item {
    margin-bottom: 12px;

}

.STEP_CLASSNAME_CONTACT_INFORMATION.VIEW_ONLY .formContent .MuiGrid-container:first-of-type .MuiGrid-item {
    margin-bottom: 5px;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .formContent .MuiGrid-container .MuiGrid-item {
    margin-bottom: 0;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .MuiPaper-root:nth-last-of-type(2) .formContent .MuiGrid-container .MuiGrid-item,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .MuiPaper-root:nth-last-of-type(3) .formContent .MuiGrid-container .MuiGrid-item {
    margin-bottom: 0;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.MODE_EDIT .customFormCard.identifiers {
    padding-bottom: 40px !important;
}

/*  // removed due to RPM2 - 2228
.altContact-editonly .customFormCard .MuiGrid-container:nth-of-type(2) {
  margin-top: 0;
} */

.auction-viewonly .MuiFormControlLabel-root,
.auction-editonly .MuiFormControlLabel-root {
    align-items: flex-start !important;
}

.auction-viewonly .MuiFormControlLabel-root .MuiButtonBase-root,
.auction-editonly .MuiFormControlLabel-root .MuiButtonBase-root {
    margin-top: -4px !important;
}

.auction-viewonly .formContent .MuiFormGroup-root .MuiBox-root,
.auction-editonly .formContent .MuiFormGroup-root .MuiBox-root {
    margin-bottom: 24px !important;
}

.auction-viewonly .formContent .MuiFormGroup-root .MuiBox-root:last-of-type {
    margin-bottom: 0 !important;
}

.icon-lg svg {
    scale: 1.75;
    width: 32px;
    margin-left: 5px;
}

.MuiAccordion-region .formContent .MuiGrid-item {
    margin-bottom: 4px;
}

.mt-20 {
    margin-top: 20px;
}

/* .MuiAccordion-region .revision-edit .formContent .MuiGrid-item, .MuiAccordion-region*/
/* .revision-edit .formContent .MuiBox-root{
  padding-bottom: 0;
} */
.revision-contact-edit .formContent .MuiGrid-item {
    margin-bottom: 0;
}

.revision-contact-edit .formContent .physical-address-wrapper,
.revision-contact-edit .formContent .entity-contactinfo-wrapper {
    margin-top: 6px;
}

.revision-contact-view .formContent .physical-address-wrapper {
    margin-top: 35px;
}

/* .revision-contact-view .formContent.MuiBox-root {
  padding-top: 20px;
} */

.revision-contact-view .formContent .entity-contactinfo-wrapper {
    margin-top: 35px;
}

.revision-contact-edit .formContent .entity-contactinfo-wrapper:nth-of-type(2) {
    margin-top: 35px;
}


.revision-contact-edit .formContent .entity-contactinfo-wrapper:nth-of-type(2) {
    margin-top: 35px;
}

.revision-contact-edit .formContent .entity-contactinfo-wrapper {
    margin-top: 0;
}

.MuiAccordion-region .revision-edit .formContent .MuiGrid-item .MuiTypography-root .MuiFormControl-root,
.MuiAccordion-region .revision-view .formContent .MuiGrid-item .MuiTypography-root .MuiFormControl-root {
    margin-top: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiGrid-item {
    margin-bottom: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root.identifiers .MuiGrid-item.first-row-col {
    padding-top: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root.identifiers .MuiGrid-item {
    margin-bottom: 6px;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root .MuiGrid-item .MuiOutlinedInput-root.Mui-disabled {
    color: #333;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root .MuiGrid-item .MuiOutlinedInput-root svg {
    display: none;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root .MuiGrid-item .MuiOutlinedInput-root .MuiBox-root {
    -webkit-text-fill-color: #333 !important;
    margin-left: 0;
}

.MuiAccordion-region .revision-view .identifiers-wrapper {
    margin-bottom: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root:last-of-type .MuiBox-root {
    margin-bottom: 0;
}

.MuiAccordion-region .revision-edit .formContent .MuiBox-root .MuiGrid-item .MuiSelect-select {
    padding: 3px 32px 3px 4px;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .MuiAccordion-region .revision-edit .formContent .MuiBox-root .MuiGrid-item .MuiSelect-select .MuiBox-root .MuiTypography-root .MuiBox-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 92%;
    }

    .MuiAccordion-region .revision-edit .formContent .MuiBox-root .MuiGrid-item .MuiSelect-select {
        padding: 3px 16px 3px 4px;
    }
}

.MuiAccordion-region .revision-edit .formContent .MuiGrid-item.entity-type .MuiFormControl-root {
    width: 100%;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root.identifiers .MuiTypography-root + .MuiGrid-container {
    margin-top: 7px;
}

.MuiAccordion-region .revision-view .formContent .MuiBox-root.identifiers .MuiTypography-root + .MuiGrid-container .MuiGrid-item {
    margin-top: -5px;
}

.MuiAccordion-region .revision-view .formContent .MuiGrid-item.jurisdiction-wrapper {
    margin-top: -27px;
    margin-bottom: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiGrid-item.jurisdiction-wrapper {
    margin-top: -10px;
}

.MuiAccordion-region .revision-edit .formContent .MuiGrid-item.jurisdiction-wrapper {
    margin-top: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiGrid-item .MuiTypography-root .MuiFormControl-root .MuiSelect-select {
    padding: 4px 0;
}

.MuiAccordion-region .revision-view .formContent .MuiGrid-item .MuiTypography-root .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
    border: 0;
}

.MuiAccordion-region .revision-view .formContent .MuiGrid-item .MuiTypography-root .MuiFormControl-root .MuiSelect-iconOutlined.Mui-disabled {
    display: none;
}

/* .MuiAccordion-region .revision-edit .formContent .MuiGrid-item {
  margin-bottom: 10px;
} */

.MuiAccordion-region .revision-view .formContent .MuiGrid-item.entity-type {
    margin-top: 16px;
    margin-bottom: 26px;
}

.MuiAccordion-region .revision-edit .formContent .MuiGrid-item.entity-type {
    margin-top: 16px;
}

.MuiAccordion-region .revision-edit .formContent .MuiGrid-item.entity-type .MuiTypography-root:first-of-type {
    margin-bottom: 4px;
}

/* .MuiAccordion-region .revision-view .formContent .identifiers.MuiBox-root {
  padding-bottom: 35px;
} */

.MuiAccordion-region .revision-edit .formContent .identifiers .MuiGrid-item {
    margin-bottom: 0;
}

.MuiAccordion-region .revision-edit .formContent .identifiers .MuiGrid-container:nth-of-type(2) {
    margin-top: -10px;
}

.MuiAccordion-region .revision-edit .formContent .identifiers .MuiGrid-container:nth-of-type(3) {
    margin-top: -20px;
}

/* .MuiAccordion-region .revision-contact-view .formContent .MuiBox-root:not(.label-wrapper) {
  padding-bottom: 40px;
} */

.MuiAccordion-region .revision-contact-view .formContent .MuiGrid-item {
    margin-bottom: 0;
}

.ml-label {
    height: 40px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.ml-label .MuiTypography-root {
    display: block;
    padding-top: 0;
}

.tabs-main div[role="tabpanel"] .MuiBox-root {
    padding: 10px 0;

}

.MuiTypography-h1 {
    min-height: 42px;
}

.not-applicable {
    color: #aaaaaa;
}

.accordion-card {
    background: white;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);
    border: 1px solid #cccccc;
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 20px;
}

.approveDenySection-accordiancard {
    height: 190px;
    background: white;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);
    border: 1px solid #cccccc;
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 20px;
}

.Mui-checked {
    color: #283459;
}

/* below css should hide because causes issues in facility & reviewAndSubmit page */
/* .MuiFormControlLabel-label.Mui-disabled {
  color: rgba(0, 0, 0) !important
} */


.facilityTypeText {
    color: red;
    font-size: 0.9rem !important;
}

/*RPM2-593*/
.er-steps ol li .MuiListItemButton-root {
    cursor: default;
}

.er-steps ol li .MuiListItemButton-root:hover {
    background-color: transparent;
}

/*RPM2-865*/
.MuiStepButton-root {
    width: auto !important;
}

/*RPM2-782 Spacing issue*/
.no-lable-field .label-wrapper {
    display: none;
}

.no-lable-field .MuiFormHelperText-root {
    margin-bottom: -10px !important;
}

/*RPM2-1734 Radio label UI and spacing in between */
div[role="radiogroup"] .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.MuiFormControlLabel-root:first-of-type {
    margin-right: 40px;
}

/*RPM2-782 Radio btn label UI and spacing*/
.radio-btn-group .MuiFormGroup-root[role="radiogroup"] .MuiFormControlLabel-root .MuiTypography-root {
    color: #000;
    font-weight: 700;
}

.radio-btn-group .MuiFormGroup-root[role="radiogroup"] svg {
    scale: 0.9;
    margin-top: -2px;
}

.MuiFormHelperText-root.Mui-disabled.Mui-error {
    -webkit-text-fill-color: #ca2c1c !important;
}

/* .Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0) !important;
} */

.checkbox_label {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.proposed_checkbox {
    margin-left: 4px !important;
}

.proposed_checkbox svg {
    height: 25px !important;
    width: 25px !important;
}

.custom_error_msg {
    color: #ca2c1c;
    font-size: 12px;
}

.custom_error_msg_wrapper {
    display: flex;
    min-height: 24px;
}

/* .Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0) !important;
} */

.MuiFormHelperText-root.Mui-disabled.Mui-error {
    -webkit-text-fill-color: #ca2c1c !important;
}

.request-container {
    padding: 10px;
}

.representative-viewonly {
    margin-top: 0;
}

/* .representative-editmode {
  margin-top: -20px;
} */
/* .representative-viewonly+.altContact-editonly .customFormCard {
  margin-bottom: 0;
} */

/*RPM2-714*/
.entity-actions {
    padding: 0 !important;
}

.entity-actions label.MuiFormControlLabel-root {
    margin-bottom: 4px;
}

/*RPM2-1765*/
.representative-viewonly .formContent .MuiTypography-h2,
.representative-history-viewonly .MuiGrid-grid-md-9 .MuiTypography-h2 {
    font-size: 16px;
    color: #000;
}

.representative-editmode .formContent .MuiTypography-h2,
.representative-history-viewonly .MuiGrid-grid-md-9 .MuiTypography-h2,
.accordion-representatives .MuiGrid-grid-md-9 .MuiTypography-h2 {
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
}

.accordion-representatives .edit-btn-wrapper {
    margin-bottom: 0;
}

.representative-status-history-viewonly,
.representative-status-history-editmode {
    margin-top: -20px;
}

/*RPM2-714*/
.entity-actions {
    padding: 0 !important;
}

.entity-actions label.MuiFormControlLabel-root {
    margin-bottom: 8px;
    width: fit-content;
    margin-left: 0;
}

.entity-actions label.MuiFormControlLabel-root .MuiRadio-root {
    margin-right: 18px;
}

.entity-actions .MuiInputBase-multiline textarea {
    resize: none;
    height: 41px !important;
    overflow: visible !important;
    padding: 0;
}

.request-actions .MuiPaper-root {
    padding: 20px 10px 20px 10px;
}

/*RPM2-997*/
.MuiDataGrid-cell .MuiInput-root:before {
    border: 0;
}

.MuiDataGrid-cell .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border: 0;
}

.MuiDataGrid-cell .MuiInput-root.Mui-focused:after {
    display: none;
}

.MuiDataGrid-cell .MuiInput-root {
    text-align: left;
    padding-left: 8px;
}

.MuiDataGrid-cell .MuiInput-input:focus {
    background-color: #fff;
}

.configuration-setting-intro-text {
    margin: 0 0 20px -40px;
}

/*RPM2-997*/
.MuiDataGrid-cell .MuiInput-root:before {
    border: 0;
}

.MuiDataGrid-cell .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border: 0;
}

.MuiDataGrid-cell .MuiInput-root.Mui-focused:after {
    display: none;
}

.MuiDataGrid-cell .MuiInput-root {
    text-align: left;
    padding-left: 8px;
}

.MuiDataGrid-cell .MuiInput-input:focus {
    background-color: #fff;
}

.configuration-setting-intro-text {
    margin: 0 0 20px -40px;
    color: #333333;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
}

@media (min-width: 1100px) and (max-width: 1440px) {
    .ns-grid {
        margin-top: -16px !important;
    }
}

/*452*/
.date-picker + .MuiFormControl-root {
    width: 100%;
}

.MuiCalendarPicker-root .PrivatePickersYear-yearButton {
    font-size: 14px;
}

/**/
.MuiStepper-vertical .MuiButtonBase-root:focus .MuiStepLabel-labelContainer {
    font-weight: 500;
    border: 1px solid;
}

/**/
.customFormCard.mailing-address .MuiFormControlLabel-root .MuiCheckbox-root + .MuiTypography-root {
    font-weight: 700;
}

/**/
.other-classification {
    position: relative;
}

.other-classification .label-wrapper {
    height: 8px;
}

.other-classification .MuiGrid-item.MuiGrid-grid-md-12 {
    position: absolute;
    width: 100%;
}

/* RPM2-1815 */
/* .disabledField .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.disabledField .MuiSelect-select.MuiSelect-outlined.Mui-disabled.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.disabledField input[name="mailingAddress.street1"],
.disabledField input[name="mailingAddress.street2"],
.disabledField input[name="mailingAddress.city"],
.disabledField input[name="mailingAddress.country"],
.disabledField input[name="mailingAddress.stateOrProvince"],
.disabledField input[name="mailingAddress.region"],
.disabledField input[name="mailingAddress.postalCode"] {
  background-color: #80808029;
} */

/*452*/
.date-picker + .MuiFormControl-root {
    width: 100%;
}

.MuiCalendarPicker-root .PrivatePickersYear-yearButton {
    font-size: 14px;
}

/**/
.MuiStepper-vertical .MuiButtonBase-root:focus .MuiStepLabel-labelContainer {
    font-weight: 500;
    border: 1px solid;
}

/**/
.customFormCard.mailing-address .MuiFormControlLabel-root .MuiCheckbox-root + .MuiTypography-root {
    font-weight: 700;
}

/**/
.other-classification {
    position: relative;
}

.other-classification .label-wrapper {
    height: 8px;
}

.disabledValueOnReviewAndSubmit {
    color: #80808091;
}

/*proposed data changes*/
.generic-card {
    background: white;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);
    border: 1px solid #cccccc;
    /* margin-top: 20px; */
    padding: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 300;
}

.select-all {
    margin-bottom: 6px;
    margin-left: -6px;
}

.select-all label.MuiFormControlLabel-root {
    margin-right: 0;
}

.data-changes-checkbox {
    padding: 0 10px 0 0 !important;
    /* height: 28px; */
    width: 28px;
}

.radio-group .MuiFormGroup-root .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: 400;
}

.radio-group .MuiFormGroup-root .MuiFormControlLabel-root .MuiRadio-colorPrimary + span.MuiTypography-root {
    margin-left: 8px;
}

.text-area .MuiInputBase-root {
    padding: 10px;
}

.SearchableDropdown .MuiInputBase-root {
    padding: 0px !important;
}

.SearchableDropdown .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 5px 4px 5px 5px !important;
}

.disabledDropDown {
    pointer-events: none;
}

.ico-info {
    height: 20px !important;
    width: 20px !important;
}

/**/
.card-title,
.MuiTypography-root.card-title {
    font-size: 24px;
    font-weight: 300;
    color: #000;
    margin-bottom: 20px;
}

.MuiListItemText-root.formlink-wrapper {
    flex: none;
    width: 40%;
}

.download-icon {
    position: relative;
    top: 8px;
}

/* .facilities {
  margin-top: -25px;
} */

.proposed-facility .facilities {
    margin-top: 0;
}

.text-danger {
    color: #ca2c1c;
}

/**/
.btn-radio {
    width: fit-content;
    margin-top: -15px;
}

.btn-radio:first-of-type {
    margin-top: -8px;
}

.float-error-msg {
    position: absolute;
}

.ellipsisContent {
    white-space: "no-wrap";
}

/**/
.MuiTooltip-tooltip .MuiTypography-root {
    font-size: 14px;
}

.form_links {
    color: #4d8da4;
    cursor: pointer;
    font-size: 14px;
    width: fit-content;
    display: block;
}

.form_links:hover {
    font-weight: 600;
}

.MuiDialog-paper {
    max-width: 750px;
}

.MuiDialog-paper.MuiDialog-paperWidthXl {
    max-width: 1536px !important;
}

.exit-app-modal .messageModel p.MuiTypography-body2:nth-of-type(2) {
    margin-top: 0;
}

.restrict-entity-modal .messageModel h6 + p.MuiTypography-body2 {
    margin-top: 24px;
}

.restrict-entity-modal .messageModel p.MuiTypography-body2 {
    line-height: 28px;
}

.restrict-entity-modal .messageModel .label-wrapper label {
    font-weight: 500;
    color: #000;
}

.cancelBtn:hover {
    background-color: #283459 !important;
    color: #fff !important;
}

.rep_date_header {
    line-height: 20px;
    text-transform: initial;
}

.rep_date_header_history {
    text-transform: initial;
}

.rep_date_header span {
    font-weight: 300;
}

.rep_date_header_history span {
    font-weight: 300;
}

/*898*/
.sm-tooltip .MuiTooltip-tooltipPlacementRight {
    max-width: 160px;
}

.sm-tooltip {
    margin-left: 13px !important;
}

/**/
.customFormCard {
    position: relative;
}

.edit-btn-wrapper {
    margin-bottom: 20px;
}

.STEP_CLASSNAME_SELECT_ENTITY_TYPE.MODE_ADD .customFormCard.entity-type {
    padding-bottom: 40px;
    min-height: 181px;
}

.STEP_CLASSNAME_SELECT_ENTITY_TYPE.MODE_EDIT .customFormCard.entity-type {
    padding-bottom: 40px;
    position: relative;
}

.STEP_CLASSNAME_SELECT_ENTITY_TYPE.MODE_EDIT .customFormCard.entity-type .cancel-save-btn-wrapper {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.STEP_CLASSNAME_SELECT_ENTITY_TYPE.MODE_EDIT.VIEW_ONLY .edit-btn-wrapper {
    margin-bottom: 0 !important;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.MODE_ADD .customFormCard.entity-info {
    padding-bottom: 20px;
}

.STEP_CLASSNAME_ENTITY_INFORMATION.MODE_EDIT .customFormCard.identifiers .cancel-save-btn-wrapper {
    margin-top: 0 !important;
}

.STEP_CLASSNAME_SELECT_JURISDICTION.MODE_ADD .customFormCard.select-jurisdiction {
    padding-bottom: 40px;
    min-height: 181px;
}

.STEP_CLASSNAME_SELECT_JURISDICTION.MODE_EDIT .customFormCard.select-jurisdiction {
    padding-bottom: 40px;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .customFormCard.physical-address .cancel-save-btn-wrapper,
.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .customFormCard.mailing-address .cancel-save-btn-wrapper {
    margin-top: 0 !important;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .customFormCard.physical-address {
    padding-bottom: 40px !important;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .customFormCard.mailing-address {
    padding-bottom: 40px !important;
}

.STEP_CLASSNAME_CONTACT_INFORMATION.MODE_EDIT .additional_contact_information.customFormCard .cancel-save-btn-wrapper {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

/* .add-representatives .edit-btn-wrapper {
  margin-bottom: 45px !important;
} */
.review-submit-representatives .add-representatives .edit-btn-wrapper {
    margin-bottom: 37px !important;
}

/* .MuiAccordion-region .MuiGrid-item.edit-btn-wrapper{
  margin-bottom: 0 !important;
} */

/*1078*/
.MuiDataGrid-filterFormValueInput .MuiNativeSelect-select,
.MuiDataGrid-filterFormValueInput input {
    padding-left: 10px !important;
}

.download_form_div {
    margin: 40px;
}

.download_btn_div {
    display: flex;
    justify-content: flex-end;
}

.download_btn {
    margin-left: auto;
}

/* RPM2-1640*/
.messageModel:focus-visible {
    outline: none;
}

/*RPM2-1684*/
.sm-text-area-wrapper .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 11px 14px;
}

/* RPM2-1585*/
.dot-icon:focus {
    outline: none;
}

/* RPM2-1695*/
.passphrase-dialog .MuiDialogContent-root {
    padding-top: 20px !important;
}

/*RPM2-1747*/
.card-info.MuiBox-root {
    background-color: #ffffff;
    border: 0;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);
    margin-top: 6px;
    margin-bottom: 40px;
}

/*RPM2-1781*/
.invalidate-cwi .messageModel h6.MuiTypography-subtitle1 {
    margin-bottom: 25px;
}

.invalidate-cwi .messageModel .MuiTypography-root {
    line-height: 30px;
}

/*RPM2-1787*/
.enter-pass-rev-req .MuiDialog-paper {
    border: solid 2px #283459;
    border-radius: 8px;
}

/*RPM2-1570*/
.customFormCard.add-facility .MuiGrid-container + .MuiFormGroup-root {
    margin-top: 8px;
}

/*RPM2-1684*/
@media (min-width: 1024px) and (max-width: 1280px) {
    .s-label .label-wrapper .MuiInputLabel-root {
        width: 90%;
    }
}

.representative-viewonly svg.Mui-disabled,
.hideForJurisdictionUser,
.hidden {
    display: none;
}

.representative-viewonly .MuiSelect-select.Mui-disabled {
    -webkit-text-fill-color: #333333ed;
}

.customMenuItem {
    color: #4d8da4 !important;
}

.customMenuItem:hover {
    color: #ffffff !important;
}

.checkbox-physical-address {
    display: flex;
    align-items: center;
    margin: 15px 0 15px 0;
}

.checkbox-physical-address .MuiFormControlLabel-root .MuiFormControlLabel-label.MuiTypography-root {
    font-weight: 700;
}

/*1967*/
.other-classification .label-wrapper + .MuiTypography-root {
    height: 20px;
    overflow-y: auto;
}

/*1787*/
.new-rep-dialog .messageModel h6.MuiTypography-subtitle1 {
    margin-bottom: 46px;
}

.new-rep-dialog .messageModel .MuiTypography-root .form_links {
    margin: 12px 0;
}

.new-rep-dialog .messageModel .bottom-btn {
    margin-top: 22px !important;
}

/*RPM2-2052*/
@media (min-width: 1600px) and (max-width: 1920px) {
    .facility-identifiers .s-label .label-wrapper .MuiInputLabel-root {
        width: 95%;
    }
}

.corporateflexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group_id_link {
    font-weight: 600;
    font-size: 16px;
    color: black;
    text-decoration: none;
}

.entityIdLabel {
    color: #4d8da4;
}

.navlinkPageTitle {
    margin: 0;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.167;
    margin-bottom: 0px !important;
    text-decoration: none;
    color: #333333;
}

/*RPM2-1947*/
.revision-view .other-field-wrapper {
    margin-top: -27px;
}

.revision-edit .other-field-wrapper {
    margin-top: -17px;
}

.revision-edit .type-of-org-wrapper {
    margin-top: -16px;
}

.revision-view .type-of-org-wrapper {
    margin-top: -24px;
}

.altContact-editonly .customFormCard {
    padding-bottom: 40px;
}

.MuiPaper-root .customFormCard {
    padding-bottom: 40px !important;
}

/*RPM2-1814*/
.review-submit-representatives .MuiTypography-h2 {
    margin-bottom: 4px;
}

.input-group-select svg.Mui-disabled {
    display: none;
}

.input-dropdown-disabled {
    background-color: #AAAAAA !important;
    color: #FFFFFF !important;
    border-radius: 4px;
}

.radio-group .MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0) !important;
}

/* RPM2-1720 */
.MuiDataGrid-columnsPanel .MuiDataGrid-columnsPanelRow .MuiFormControlLabel-root .MuiTypography-root {
    text-transform: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/*RPM2-1826 */
.MuiDataGrid-columnsPanel.MuiDataGrid-columnsPanel {
    text-decoration: inherit;
}

.MuiDataGrid-columnHeaderTitle {
    text-transform: initial;
}

.Header_headtext {
    color: white;
    padding-left: 20px;
    margin-bottom: 5px;
}

/*RPM2-2122*/
.chip-wrapper .MuiAutocomplete-inputRoot.MuiOutlinedInput-root {
    height: 30px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.chip-wrapper .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.Mui-disabled .MuiAutocomplete-endAdornment {
    display: none;
}

.chip-wrapper .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 0;
}

.chip-wrapper .MuiAutocomplete-tag {
    background-color: #e0ebef;
    border: #4d8da4 solid 1px;
    color: #333333;
    height: 18px;
    margin: 0 2px;
}

.chip-wrapper .MuiAutocomplete-tag.Mui-disabled {
    background-color: #d7d7d7;
    border: #aaaaaa solid 1px;
    font-size: 12px;
    margin: 0 0 0 5px;
    opacity: 1;
}

.chip-wrapper .MuiAutocomplete-tag.Mui-disabled:first-of-type {
    margin: 0;
}

.chip-wrapper .MuiAutocomplete-tag svg path {
    fill: #4d8da4;
}

.chip-wrapper .MuiAutocomplete-tag.Mui-disabled svg path {
    fill: #aaaaaa;
}

.chip-wrapper .MuiAutocomplete-tag .MuiChip-deleteIcon {
    font-size: 16px;
}

.chip-wrapper input#country-select-demo {
    padding: 0;
}

.CagTable,
.representativeTable {
    font-weight: 500;
}

.custumCagMembers {
    margin-top: 20px;
}

.addCag #jurisdiction {
    padding: 2.5px 4px 2.5px 1px;
}

.NonEditable .MuiDataGrid-cell.MuiDataGrid-cell--editing {
    pointer-events: none;
}

/*RPM2-2124*/
.cag-details .customFormCard.cag-info {
    margin-bottom: 0;
}

.cag-details .customFormCard.cag-members {
    padding-bottom: 20px;
    margin-bottom: 0;
}

.cag-details .customFormCard.cag-members .edit-btn-wrapper {
    margin-bottom: 0 !important;
}

.cag-details .customFormCard.cag-members .MuiDataGrid-root {
    margin-bottom: 20px;
}

.cag-details .customFormCard.cag-members .formContent h2.MuiTypography-h2 {
    margin-top: 40px !important;
}

.cag-details .customFormCard.cag-members .formContent + .cancel-save-btn-wrapper {
    margin-top: 0 !important;
}

.cag-details .customFormCard.cag-status-history {
    margin-bottom: 40px;
}

.d-none {
    display: none;
}

.first-column-css {
    background-color: #e6eff2;
    font-size: 14px;
    line-height: 42px;
    font-family: Open Sans;
    color: #333333;
    font-weight: 600;
}

.customBtn-margin {
    margin-left: 0px;
}

@media print {
    .printable-table {
        display: block !important;
    }

    .printable-table tr {
        page-break-inside: avoid;
    }
}

.linkHoverDesign:hover {
    font-weight: 600;
}

/* Used for text field where we need to fix old n new value spacing issues */
.box-label-wrapper {
    display: flex;
    align-items: flex-end;
    height: 20px;
}

.custom-box-label-wrapper {
    display: flex;
    align-items: flex-end;
    height: 35px;
}

.pending-accordion .MuiAccordionDetails-root.accordian-padding-top-unset {
    padding: 0 0 20px 0;
}

.link-cell:hover {
    font-weight: 700 !important;
}

.proposed-facility-table .MuiDataGrid-main + div {
    display: none;
}

.auctionText {
    color: #333333 !important;
}

.header-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    line-height: normal;
    white-space: break-spaces;
    font-weight: 600;
}

.header-wrap-sm {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    line-height: normal;
    white-space: break-spaces;
    font-weight: 600;
}

.represetativeText {
    font-size: 24px !important;
}

.without-proposed-changes {
    height: 68px;
}

.with-proposed-changes {
    height: 88px;
}

.MuiPaper-root .quick-link-style .MuiList-root p:focus,
.MuiPaper-root .quick-link-style .MuiList-root p:focus-visible {
    border: none !important;
    outline: none !important;
    font-weight: bold;
    /* text-decoration: underline; */
    background-color: #4d8da4;
    color: #fff;
    font-weight: 500;
}

.quick-link-style .MuiList-root p:focus,
.quick-link-style .MuiList-root p:focus-visible,
a:focus,
a:focus-visible,
    /* button:focus,  removed due to RPM-2501 */
button:focus-visible {
    border: none !important;
    outline: none !important;
}

a.disabledLink:focus p,
a.disabledLink:focus-visible p,
a.disabledLink:hover p,
.MuiDataGrid-main a:focus p,
.MuiDataGrid-main a:focus-visible p,
.MuiDataGrid-main a:hover p,
.MuiDataGrid-root a.disabledLink:focus p,
.MuiDataGrid-root a.disabledLink:focus-visible p,
.MuiDataGrid-root a.disabledLink:hover p {
    font-weight: bold;
    text-decoration: underline;
}

.header-menu-popup-state .MuiBox-root a:focus p,
.header-menu-popup-state .MuiBox-root a:focus-visible p,
.header-menu-popup-state .MuiBox-root a:focus,
.header-menu-popup-state .MuiBox-root a:focus-visible {
    font-weight: 600 !important;
    text-decoration: underline !important;
    border: none !important;
    outline: none !important;
}

.header-menu-popup-state .MuiPaper-root.MuiPaper-elevation {
    width: -webkit-fill-available;
    right: 2rem;
}

.disabled-datagrid-row {
    background-color: lightgray !important;
    color: white;
}

.header-menu-popup-state .MuiMenuItem-root {
    background-color: transparent !important;
    white-space: normal;
}


/* '&:hover': {
  fontWeight: "800",
  color: "red",
},
'&:focus': {
  border: "1px solid #4D8DA4 !important",
  outline: "1px solid #4D8DA4 !important",
},
'&:focus-within': {
  border: "1px solid #4D8DA4 !important",
  outline: "1px solid #4D8DA4 !important",
} */